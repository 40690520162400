export const propertyMarkerExamples = [
  {
    name: "City level",
    markdown: `From this height, you'll see a small black circle with a thick gray border.
    ![Small property marker](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)`,
  },
  {
    name: "Local level",
    markdown: `Closer to the ground, the marker enlarges to a black rectangle with a rounded gray border.
    ![Large property marker](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)`,
  },
  {
    name: "Details pop-up",
    markdown: `When you point at the marker, it turns purple and displays a pop-up with additional data.
    ![Property marker menu](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)`,
  },
  {
    name: "Linked tasks",
    markdown: `
If there are {% inlineRouterLink %}prioritized tasks{% /inlineRouterLink %}  linked to the property, the border changes color to indicate the highest available task priority.
![Task priority marker](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
{% callout type="tip" %}
**Tip:** You can add a new task without leaving the map by using the {% inlineRouterLink articleId="map-markers" sectionName="Real estate marker interactions" %}datapoint action menu{% /inlineRouterLink %}.
{% /callout %}`,
  },
];

export const landCoveringMarkerExamples = [
  {
    name: "Neighborhood level",
    markdown: `You'll see a black circle with a thin gray border.
    ![Land covering marker](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)`,
  },
  {
    name: "Details pop-up",
    markdown: `When you point at the marker, it turns teal and displays a pop-up with additional data.
    ![Land covering marker menu](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)`,
  },
];

export const landParcelMarkerExamples = [
  {
    name: "Neighborhood level",
    markdown: `You'll see a teal polygon with a thin teal border.
    ![Land parcel marker](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)`,
  },
  {
    name: "Details pop-up",
    markdown: `When you click on the marker, it darkens and displays a pop-up with additional data.
    ![Land covering marker menu](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)`,
  },
];

export const mapMarkerPopUp = [
  {
    name: "Datapoint",
    markdown: `
Click the marker's associated {% inlineRouterLink %}datapoint{% /inlineRouterLink %} to access its full {% inlineRouterLink %}action menu{% /inlineRouterLink %}. Highlights:
- {% inlineAppIcon iconName="pencilSquare" /%} {% inlineRouterLink %}Read and write notes{% /inlineRouterLink %}
- {% inlineAppIcon iconName="star" /%} {% inlineRouterLink %}Create a task{% /inlineRouterLink %}
- {% inlineAppIcon iconName="documentCheck" /%} {% inlineRouterLink %}View related tasks{% /inlineRouterLink %}`,
  },
  {
    name: "Diagram actions",
    markdown: `
Depending upon where you are in Tower Hunt, certain {% inlineRouterLink %}property diagram{% /inlineRouterLink %} actions may be available:
- {% inlineAppIcon iconName="rectangleStack" /%} View this marker's related diagram
- {% inlineAppIcon iconName="squaresPlus" /%} {% inlineRouterLink %}Add this marker's related property to the diagram{% /inlineRouterLink %}
- {% inlineAppIcon iconName="cursor" /%} Select this marker's related datapoint in the diagram`,
  },
];

export const mapMarkers = {
  order: 6,
  name: "Map markers",
  subcategory: "The map",
  markdown: `# Map markers

  Map markers indicate where {% inlineRouterLink articleId="what-is-a-property?" %}properties{% /inlineRouterLink %}, {% inlineRouterLink %}land coverings{% /inlineRouterLink %}, {% inlineRouterLink %}land parcels{% /inlineRouterLink %}, and active users are located. You may see them in any {% inlineRouterLink articleId="explored-areas" %}explored area{% /inlineRouterLink %} on any of the {% inlineRouterLink articleId="the-role-of-the-map" %}maps{% /inlineRouterLink %} in Tower Hunt.

  ## Property markers

  In Tower Hunt, {% inlineRouterLink articleId="what-is-a-property?" %}properties{% /inlineRouterLink %} act as containers for other datapoints. Every property has its own location, which dictates where its marker appears.

  {% callout type="tip" %}
  **Tip:** You can add properties to Tower Hunt all by yourself! Learn how to {% inlineRouterLink articleId="create-a-property" %}create a property{% /inlineRouterLink %}.
  {% /callout %}

  ### Appearance

  Property markers appear at the city zoom level and increase in size as you zoom in further. Explore the forms they can take below:

  {% tabbedContainer tabs=$propertyMarkerExamples /%}

  ## Land covering markers

  Buildings and other surface structures (ex. parking lots) may be linked to properties. They may also have their own locations distinct from the property's location.

  ### Appearance

  Land covering markers appear at the neighborhood level. Explore the forms they can take below:

  {% tabbedContainer tabs=$landCoveringMarkerExamples /%}

  ## Land parcel markers

  Land parcels may have a datapoint containing their approximate boundaries.

  ### Appearance

  Land parcel markers appear at the neighborhood level. Explore the forms they can take below:

  {% tabbedContainer tabs=$landParcelMarkerExamples /%}

  ## Real estate marker interactions

  The property, land covering, and land parcel markers offer multiple interactions to help you be more efficient.

  ### Quickly access the property page

  Clicking on a property or land covering marker will send you to the related property page.

  ### Details pop-up

  Pointing at a property or land covering marker, and clicking on a land parcel marker, reveals a pop-up with additional interactions:

  ![Annotated property marker menu](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% tabbedContainer tabs=$mapMarkerPopUp /%}

  ## User markers

  From time to time, you may see square markers featuring a Tower Hunt logo against a colored background. These represent other users who are working in Tower Hunt. The marker color corresponds to the user's {% inlineRouterLink %}reputation level{% /inlineRouterLink %}. The users are purposefully not identified &mdash; their presence is intended to be a fun reminder that Tower Hunt is always evolving.

  ![User markers](https://assets.towerhunt.com/site/userMarkers0.png)`,
};
